module directives {
    export module applicationcore {
        interface IExchangeRateScope extends ng.IScope {
            baseCurrencyCode: string;
            toCurrencyCode: string;
            exchangeRateTypeId: number;
            exchangeRateDate: string;
            exchangeRate: number;
            exchangeRateDateOpen: boolean;
            exchangeRateTypeList: Array<interfaces.applicationcore.IClassificationValue>;
        }

        export class exchangerateDirective implements ng.IDirective {

            template = `<form name="{{name}}">
                            <fieldset class="gts-fieldset" ng-disabled="ngDisabled">
                                <legend>
                                    Exchange Rate
                                </legend>
                                <div class="row vertical-align">
                                    <div class="col-md-6">
                                        <opt></opt><label>Currency</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="input-group input-group-sm">
                                            From {{toCurrencyCode}} To {{baseCurrencyCode}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row vertical-align">
                                    <div class="col-md-6">
                                        <req></req><label>Exchange Rate Type</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="input-group-sm has-feedback" ng-class="{'has-error': {{name}}.exchangeRateType.$invalid}">
                                            <select name="exchangeRateType" class="form-control" ng-model="exchangeRateTypeId" required>
                                                <option ng-repeat="type in exchangeRateTypeList" ng-value="type.Id">
                                                    {{type.Description}}
                                                </option>
                                            </select>
                                        </p>
                                    </div>
                                </div>
                                <div class="row vertical-align">
                                    <div class="col-md-6">
                                        <req></req><label>Exchange Rate Date</label>
                                    </div>
                                    <div class="col-md-6">
                                        <gts-date format="yyyy/MM/dd" ng-model="exchangeRateDate" name="exchangeRateDate" required></gts-date>
                                    </div>
                                </div>
                                <div class="row vertical-align">
                                    <div class="col-md-6">
                                        <opt></opt><label>Exchange Rate</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="input-group-sm">
                                            {{exchangeRate | number:6}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row vertical-align">
                                    <div class="col-md-6"><label></label></div>
                                    <div class="col-md-6">
                                        <div class="pull-right">
                                            <button type="button" class="btn btn-primary" ng-click="recalculateRate()">Recalculate</button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>`;
            scope = {
                baseCurrencyCode: "@",
                toCurrencyCode: "=",
                name: '@',
                exchangeRateTypeId: "=",
                exchangeRateDate: "=",
                exchangeRate: "=",
                ngDisabled: "=?",
                recalculateRate: '&?'
            }

            public ExchangeRateTypeList: Array<interfaces.applicationcore.IClassificationValue>;

            constructor(private generalService: interfaces.applicationcore.IGeneralService, private classificationValueService: interfaces.applicationcore.IClassificationValueService) {
            }

            link = ($scope: IExchangeRateScope, $element: ng.IAugmentedJQuery) => {
                $scope.exchangeRateDateOpen = false;

                var search = {
                    classificationId: 43
                };

                this.classificationValueService.GetClassificationValueByClassification().query(search, (list: Array<interfaces.applicationcore.IClassificationValue>) => {
                    $scope.exchangeRateTypeList = list;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, classificationValueService) => new exchangerateDirective(generalService, classificationValueService);
                directive.$inject = ["generalService", "classificationValueService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsExchangeRate", exchangerateDirective.factory());
    }
}